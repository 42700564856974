import React, { useEffect, useState, useRef } from "react";
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import Select from 'react-select';

import "./styles/AdminDashboard.css";

const ReservationSelectorTable = ({ reservations, onStatusChange, setUpdateMessage }) => {
    const tableRef = useRef(null);
    const [tableHeight, setTableHeight] = useState(0);
    const statusOptions = [
        { value: 'pending', label: 'En attente' },
        { value: 'pending_payment', label: 'En attente de paiement' },
        { value: 'done', label: 'Confirmée' },
        { value: 'deposit_valid', label: 'Dépôt validé' },
        { value: 'cancel', label: 'Annulée' },
        { value: 'cancel_host', label: 'Annulation Hôte' },
        { value: 'blocked', label: 'Bloquée' },
    ];

    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        if (tableRef.current) {
            setTableHeight(tableRef.current.offsetHeight);
        }
    }, [reservations]); 

    useEffect(() => {
        setStatuses(reservations.map(reservation => reservation.status));
    }, [reservations]); 

    const handleStatusChange = (selectedOption, index) => {
        const updatedValue = selectedOption ? selectedOption.value : reservations[index].status;
        const updatedStatuses = [...statuses];
        updatedStatuses[index] = updatedValue;
        setStatuses(updatedStatuses);
        setUpdateMessage("");
    };
    

    const sendStatusChange = (reservationId, index) => {
        const newStatus = statuses[index];
        onStatusChange({ reservationId, newStatus });
    };

    return (
        <div className="admin-dashboard-reservations-table" style={{ height: `${tableHeight + 350}px` }}>
            <h2>Réservation en cours</h2>
            <table ref={tableRef}>
                <thead>
                    <tr>
                        <th>Reservation</th>
                        <th>Date d'arrivée</th>
                        <th>Date de départ</th>
                        <th>Email</th>
                        <th>Nom</th>
                        <th>Adresse</th>
                        <th>TVA</th>
                        <th>Gîte</th>
                        <th>Prix total</th>
                        <th>Prix payé</th>
                        <th>Status</th>
                        <th>Valider</th>
                    </tr>
                </thead>
                <tbody>
                    {reservations.map((reservation, index) => (
                        <tr key={reservation.reservationId}>
                            <td>{reservation.reservationId}</td>
                            <td>{new Date(reservation.arrival_date).toLocaleDateString('fr-FR')}</td>
                            <td>{new Date(reservation.departure_date).toLocaleDateString('fr-FR')}</td>
                            <td>{reservation.email}</td>
                            <td>{reservation.name}</td>
                            <td>{reservation.address}</td>
                            <td>{reservation.TVA}</td>
                            <td>{reservation.giteName}</td>
                            <td>{reservation.total_amount}</td>
                            <td>{reservation.paymentAmount}</td>
                            <td>
                            {reservation.origin === "website" ?
                                <Select
                                    value={statusOptions.find(option => option.value === statuses[index])}
                                    onChange={selectedOption => handleStatusChange(selectedOption, index)}
                                    options={statusOptions}
                                    isClearable={true}
                                    isSearchable={true}
                                    className="admin-dashboard-reservations-select-status"
                                /> : "Airbnb"
                            }
                            </td>
                            <td>
                                {reservation.origin === "website" &&(
                                    <button className="admin-dashboard-reservations-button" onClick={() => sendStatusChange(reservation.reservationId, index)}>Modifier le statut</button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const AdminDashboard = () => {
    const [reservations, setReservations] = useState([]);
    const [selectedGite, setSelectedGite] = useState('');
    const [arrivalDate, setArrivalDate] = useState('');
    const [departureDate, setDepartureDate] = useState('');
    const [unavailableStartDates, setUnavailableStartDates] = useState([]);
    const [unavailableEndDates, setUnavailableEndDates] = useState([]);
    const [reservationsDates, setReservationsDates] = useState([]);
    const [minArrivalDate, setMinArrivalDate] = useState(new Date());
    const [maxDepartureDate, setMaxDepartureDate] = useState(null);
    const [updateMessage, setUpdateMessage] = useState("");
    const [gites, setGites] = useState([]);
    const { user } = useAuth();
    const { navigate } = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    //useEffect
    useEffect(() => {
        fetch(`/data/fr/gites.json`)
          .then(response => response.json())
          .then(data => setGites(data.gites))
          .catch(error => console.error('Erreur lors du chargement des gîtes:', error));
    }, []); 

    useEffect(() => {
        const fetchUnavailableDates = async () => {
            try {
                const response = await fetch(`${apiUrl}/UnavailableDate/${selectedGite}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                const { reservations, seasonPeriods, weekPeriod } = data;
    
                const getDatesInRange = (startDate, endDate, excludeDays = [], excludeStart = false) => {
                    const start = new Date(startDate);
                    const end = new Date(endDate);
                    const dates = [];
    
                    const adjustedStart = excludeStart ? new Date(start.setDate(start.getDate() + 1)) : start;

                    for (let date = new Date(adjustedStart); date <= end; date.setDate(date.getDate() + 1)) {
                        if (!excludeDays.includes(date.getDay())) {
                            dates.push(new Date(date).toISOString().split('T')[0]);
                        }
                    }
    
                    return dates;
                };
    
                let allReservationStartDates = [];
                reservations.forEach(reservation => {
                    const dates = getDatesInRange(reservation.arrival_date, reservation.departure_date);
                    allReservationStartDates = allReservationStartDates.concat(dates);
                });

                let allReservationEndDates = [];
                reservations.forEach(reservation => {
                    const dates = getDatesInRange(reservation.arrival_date, reservation.departure_date, [], true);
                    allReservationEndDates = allReservationEndDates.concat(dates);
                });
                
                let allSeasonDates = [];
                seasonPeriods.forEach(period => {
                    if (period.date_start1 && period.date_end1) {
                        const dates = getDatesInRange(period.date_start1, period.date_end1, [weekPeriod]);
                        allSeasonDates = allSeasonDates.concat(dates);
                    }
                    if (period.date_start2 && period.date_end2) {
                        const dates = getDatesInRange(period.date_start2, period.date_end2, [weekPeriod]);
                        allSeasonDates = allSeasonDates.concat(dates);
                    }
                });
    
                const combinedStartDates = [...new Set([...allReservationStartDates, ...allSeasonDates])]; 
                const combinedEndDates = [...new Set([...allReservationEndDates, ...allSeasonDates])]; 
    
                setUnavailableStartDates(combinedStartDates);
                setUnavailableEndDates(combinedEndDates);
                setReservationsDates(allReservationStartDates);

            } catch (error) {
                console.error("Erreur lors de la récupération des dates indisponibles:", error);
            }
        };
    
        if (selectedGite) {
            fetchUnavailableDates();
        }
    }, [selectedGite, apiUrl]);

    useEffect(() => {
        if (arrivalDate) {
            const firstUnavailableAfterArrival = reservationsDates
                .map(date => new Date(date))
                .sort((a, b) => a - b)
                .find(date => date > arrivalDate);

            if (firstUnavailableAfterArrival) {
                const dayBefore = new Date(firstUnavailableAfterArrival);
                dayBefore.setDate(dayBefore.getDate() - 0);
                setMaxDepartureDate(dayBefore);
            } else {
                setMaxDepartureDate(null);
            }
        }
    }, [arrivalDate, reservationsDates]);

    const handleArrivalDateChange = (date) => {
        setArrivalDate(date);
        setDepartureDate('');
    };    

    const handleChangeGite = (e) => {
        const newGiteId = e.target.value;
        setSelectedGite(newGiteId);
    
        setArrivalDate('');
        setDepartureDate('');
        setUpdateMessage("");
    };

    useEffect(() => {
        if (!user) {
            navigate("/");
            return;
        }

        const fetchReservations = async () => {
            try {
                const response = await fetch(`${apiUrl}/getReservations`, {
                    credentials: 'include',
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setReservations(data);
            } catch (error) {
                console.error('Erreur lors de la récupération des réservations:', error);
            }
        };

        fetchReservations();

    }, [apiUrl, navigate, user]);

    const blockDates = async () => {
        if (!user) {
            navigate("/");
            return;
        }

        const blockDates = { 
            giteId: selectedGite,
            arrivalDate,
            departureDate
        };

        fetch(`${apiUrl}/blockDates`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(blockDates)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            console.log("Success:", data);
            setUpdateMessage("Dates bloquées");
        })
        .catch((error) => {
            console.error("Error:", error);
            setUpdateMessage(`Échec, dates non bloquées : ${error}`);
        });
    };


    const onStatusChange = async ({ reservationId, newStatus }) => {
        if (!user) {
            navigate("/");
            return;
        }

        const statusUpdatePayload = { reservationId, status: newStatus };

        try {
            const response = await fetch(`${apiUrl}/updateReservationStatus`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify(statusUpdatePayload)
            });

            if (!response.ok) {
                throw new Error(`Erreur HTTP: ${response.status}`);
            }

            const data = await response.json();
            console.log("Success:", data);
            setUpdateMessage("Réservation mise à jour avec succès.");
        } catch (error) {
            console.error("Error:", error);
            setUpdateMessage(`Échec de la mise à jour de la réservation : ${error}`);
        }
    };

    const isBlockDatesButtonDisabled = selectedGite === '' || arrivalDate === '' || departureDate === '';

    return(
        <>
            {user && (
                <main className="admin-dashboard">
                    <div className="admin-dashboard-block-dates-container">
                        <h1>Bloquer des dates</h1>
                        <div className="admin-dashboard-block-dates-selector">
                            <select
                                    value={selectedGite}
                                    onChange={handleChangeGite}
                                >
                                    <option value="">Sélectionner le gite</option>
                                    {gites.map((gite) => (
                                        <option key={gite.id} value={gite.id}>{gite.name}</option>
                                    ))}
                            </select>
                        </div>
                        <div className="admin-dashboard-dates">
                            <ReactDatePicker
                                selected={arrivalDate}
                                onChange={handleArrivalDateChange}
                                excludeDates={unavailableStartDates}
                                minDate={minArrivalDate}
                                disabled={!selectedGite}
                                locale="fr"
                                dateFormat="dd-MM-yyyy"
                                placeholderText="Date d'arrivée"
                                className="admin-dashboard-dates-calendar"
                            />
                            <ReactDatePicker
                                selected={departureDate}
                                onChange={date => setDepartureDate(date)}
                                excludeDates={unavailableEndDates}
                                minDate={arrivalDate ? new Date(new Date(arrivalDate).setDate(new Date(arrivalDate).getDate() + 1)) : new Date()}
                                maxDate={maxDepartureDate}
                                disabled={!selectedGite || !arrivalDate}
                                locale="fr"
                                dateFormat="dd-MM-yyyy"
                                placeholderText="Date de départ"
                                className="admin-dashboard-dates-calendar"
                            />
                        </div>
                        <button disabled={isBlockDatesButtonDisabled} className="admin-dashboard-block-button" onClick={blockDates}>Bloquer les dates</button>
                    </div>

                    <div className="admin-dashboard-ranking-view">
                        {updateMessage && (<p>{updateMessage}</p>)}
                        <ReservationSelectorTable
                            reservations={reservations}
                            onStatusChange={onStatusChange}
                            setUpdateMessage={setUpdateMessage}
                        />                        
                    </div>                      
                </main>
            )}
        </>
    );
}

export default AdminDashboard;